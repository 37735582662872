import React from 'react'
import './Company.css'
const Company = () => {
  return (
   <section className='comp-wrap'>
    <div className='comp-container'>
        <img src="./prologis.png" alt="" />
        <img src="./tower.png" alt="" />
        <img src="./equinix.png" alt="" />
        <img src="./realty.png" alt="" />
    </div>
   </section>
  )
}

export default Company